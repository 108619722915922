import { isNotOnBlocklistedRoute } from '../../helpers'

function isAllowedForCurrentBrowser(brazeSdk) {
  const isPushSupported = brazeSdk.isPushSupported()
  const isAlreadyGranted = brazeSdk.isPushPermissionGranted()
  const isAlreadyBlocked = brazeSdk.isPushBlocked()

  return isPushSupported && !isAlreadyGranted && !isAlreadyBlocked
}

function canBePromptedAgain({
  nowInMs,
  msToWaitBetweenPrompts,
  msSinceLastDeclined,
}) {
  const timeElapsedInMs = nowInMs - msSinceLastDeclined

  return timeElapsedInMs > msToWaitBetweenPrompts
}

export function canDisplayToast({
  brazeSdk,
  nowInMs,
  msSinceLastDeclined,
  msToWaitBetweenPrompts,
  doNotDisturbRoutesCollections,
  currentRoute,
}) {
  if (!brazeSdk) {
    return false
  }

  const isAllowed = isAllowedForCurrentBrowser(brazeSdk)

  const canBePrompted = canBePromptedAgain({
    nowInMs,
    msToWaitBetweenPrompts,
    msSinceLastDeclined,
  })

  const canBeDisturbed = isNotOnBlocklistedRoute({
    blocklistedRoutesCollections: doNotDisturbRoutesCollections,
    currentRoute,
  })

  // We don't want to display the soft prompt on browsers that don't support web push,
  // or if the user has already granted/blocked permission or declined the soft prompt recently
  // and finally we don't want to prompt users on critical paths
  return isAllowed && canBePrompted && canBeDisturbed
}
