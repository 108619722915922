// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1DE90l9DCRR7dKxImUnlzB{-webkit-animation:_1DE90l9DCRR7dKxImUnlzB 4s ease-in-out .2s infinite;animation:_1DE90l9DCRR7dKxImUnlzB 4s ease-in-out .2s infinite}@-webkit-keyframes _1DE90l9DCRR7dKxImUnlzB{0%{transform:rotate(0deg)}9%{transform:rotate(20deg) scaleX(1)}14%{transform:rotate(-20deg) scale3d(1.1,1.1,1)}18%{transform:rotate(5deg)}25%{transform:rotate(0deg)}}@keyframes _1DE90l9DCRR7dKxImUnlzB{0%{transform:rotate(0deg)}9%{transform:rotate(20deg) scaleX(1)}14%{transform:rotate(-20deg) scale3d(1.1,1.1,1)}18%{transform:rotate(5deg)}25%{transform:rotate(0deg)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bounce": "_1DE90l9DCRR7dKxImUnlzB"
};
module.exports = ___CSS_LOADER_EXPORT___;
