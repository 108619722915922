
import {
  RevButtonDefault,
  RevIllustration,
  RevLink,
} from '@backmarket/design-system'
import { mapGetters } from 'vuex'

import { MARKETPLACES } from '@config/constants'
import FormattedMessage from '@i18n/components/FormattedMessage'
import { ROUTES } from '@router'
import { trackClick } from '@tracking/events'

import { COOKIES_TRACKING_NAME } from '../../constants'

import translations from './CookiesModal.translations'

export default {
  components: {
    RevButtonDefault,
    RevLink,
    RevIllustration,
    FormattedMessage,
  },

  props: {
    marketplace: {
      type: String,
      required: true,
    },
    zone: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      windowHeight: 0,
    }
  },

  computed: {
    ...mapGetters({
      country: 'config/country',
    }),
    translations: () => translations,
    ROUTES: () => ROUTES,
    displayRefuseAllButton() {
      return this.marketplace !== MARKETPLACES.NA
    },
    cookieImage() {
      return this.isCatCampaignEnabled
        ? this.$static('/img/catpaign/cat-cookie.png')
        : this.$static('/img/gdpr/cookie.png')
    },
    isCatCampaignEnabled() {
      return this.$config.FF_CAT_CAMPAIGN_BY_COUNTRY?.includes(this.country)
    },
  },

  mounted() {
    this.windowHeight = window.innerHeight
  },

  methods: {
    handleClickAccept() {
      trackClick({ zone: this.zone, name: COOKIES_TRACKING_NAME.ACCEPT })
      this.$emit('accept')
    },
    handleClickRefuse() {
      trackClick({ zone: this.zone, name: COOKIES_TRACKING_NAME.REFUSE })
      this.$emit('refuse')
    },
    handleClickParams() {
      trackClick({ zone: this.zone, name: COOKIES_TRACKING_NAME.SETTINGS })
      this.$emit('params')
    },
    handleClickCookiesTerms() {
      trackClick({ zone: this.zone, name: COOKIES_TRACKING_NAME.INFO })
    },
    handleClickLegalMentions() {
      trackClick({
        zone: this.zone,
        name: COOKIES_TRACKING_NAME.LEGAL_MENTIONS,
      })
    },
    handleClickDataProtection() {
      trackClick({
        zone: this.zone,
        name: COOKIES_TRACKING_NAME.DATA_PROTECTION,
      })
    },
  },
}
