
import {
  IconCross,
  RevButtonRounded,
  RevButtonTiny,
  RevIllustration,
  RevPaper,
} from '@backmarket/design-system'
import { mapGetters } from 'vuex'

import localStorage from '@core/helpers/localStorage'
import { trackClick, trackPromptNotificationImpression } from '@tracking/events'

import { getSdk } from '../braze'
import {
  DO_NOT_DISTURB_ROUTES_COLLECTIONS,
  MS_TO_WAIT_BEFORE_SHOWING_SOFT_PROMPT,
  MS_TO_WAIT_BETWEEN_PROMPTS,
  SCROLLING_THRESHOLD_BEFORE_DISMISSAL_IN_PIXELS,
  TRACKING_EVENT_NAME,
  TRACKING_ZONE,
} from '../constants'

import translations from './BrazePrompt.translations'
import { canDisplayToast } from './helpers'

export default {
  components: {
    RevButtonRounded,
    RevButtonTiny,
    RevIllustration,
    RevPaper,
  },

  data() {
    return {
      brazeSdk: null,
      isVisible: false,
      scrollReferencePoint: 0,
    }
  },

  computed: {
    translations: () => translations,
    IconCross: () => IconCross,
    TRACKING_EVENT_NAME: () => TRACKING_EVENT_NAME,

    ...mapGetters({
      isBrazeAvailable: 'braze/isBrazeAvailable',
    }),
  },

  watch: {
    isBrazeAvailable: {
      async handler(isBrazeAvailable) {
        if (!isBrazeAvailable) {
          return
        }

        this.brazeSdk = await getSdk()

        this.displayAfter(MS_TO_WAIT_BEFORE_SHOWING_SOFT_PROMPT)
      },

      immediate: true,
    },

    isVisible() {
      this.watchScrolling()

      trackPromptNotificationImpression(TRACKING_ZONE)
    },
  },

  methods: {
    displayAfter(msToWaitBeforeDisplay) {
      const conditionsArguments = {
        brazeSdk: this.brazeSdk,
        nowInMs: Date.now(),
        msSinceLastDeclined: localStorage.getItem('declinedSoftPromptAt') || 0,
        msToWaitBetweenPrompts: MS_TO_WAIT_BETWEEN_PROMPTS,
        doNotDisturbRoutesCollections: DO_NOT_DISTURB_ROUTES_COLLECTIONS,
        currentRoute: this.$route.name,
      }

      setTimeout(() => {
        this.isVisible = canDisplayToast(conditionsArguments)
      }, msToWaitBeforeDisplay)
    },

    close(trackingName) {
      this.isVisible = false
      localStorage.setItem('declinedSoftPromptAt', Date.now())

      if (trackingName) {
        trackClick({
          name: trackingName,
          zone: TRACKING_ZONE,
        })
      }
    },

    handleClick() {
      this.requestPushPermission()

      trackClick({
        name: TRACKING_EVENT_NAME.ACCEPT,
        zone: TRACKING_ZONE,
      })
    },

    requestPushPermission() {
      if (!this.brazeSdk) {
        return
      }

      this.brazeSdk.requestPushPermission()
      this.isVisible = false
    },

    watchScrolling() {
      if (this.isVisible) {
        this.scrollReferencePoint = window.pageYOffset
        window.addEventListener('scroll', this.dismissAfterScroll)
      } else {
        window.removeEventListener('scroll', this.dismissAfterScroll)
      }
    },

    dismissAfterScroll() {
      const actualOffset = Math.abs(
        window.pageYOffset - this.scrollReferencePoint,
      )

      const shouldBeDismissed =
        actualOffset >= SCROLLING_THRESHOLD_BEFORE_DISMISSAL_IN_PIXELS

      if (shouldBeDismissed) {
        this.close()
      }
    },
  },
}
