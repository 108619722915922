
import { IconArrowLeft, RevButtonRounded } from '@backmarket/design-system'
import { mapGetters } from 'vuex'

import { COOKIES } from '@config/constants'
import { trackClick, trackModal } from '@tracking/events'

import { COOKIES_TRACKING_NAME, COOKIES_TRACKING_PREFIX } from '../../constants'
import Notification, { TOAST_TYPE } from '../CookiesSettings/Notification'

import translations from './CookiesModal.translations'
import FirstStep from './FirstStep.vue'
import SecondStep from './SecondStep.vue'

export default {
  components: {
    RevButtonRounded,
    FirstStep,
    Notification,
    SecondStep,
  },

  data() {
    return {
      step: 0,
      isToastOpened: false,
    }
  },

  computed: {
    ...mapGetters({
      isOpen: 'gdpr/cookies/isOpen',
      marketplace: 'config/marketplace',
    }),
    translations: () => translations,
    TOAST_TYPE: () => TOAST_TYPE,
    name: () => `${COOKIES_TRACKING_PREFIX}_${COOKIES_TRACKING_NAME.MODAL}`,
    isFirstStep() {
      return this.step === 0
    },
    isSecondStep() {
      return this.step === 1
    },
    title() {
      return this.isSecondStep ? this.$t(translations.secondStepTitle) : ''
    },
    zone() {
      return `${COOKIES_TRACKING_PREFIX}_${COOKIES_TRACKING_NAME.MODAL_STEP}${
        this.step + 1
      }`
    },
    IconArrowLeft: () => IconArrowLeft,
  },

  watch: {
    isOpen: {
      handler(value) {
        // When we close the modal we allow user to scroll on body again
        window.document.body.style.overflow = value ? 'hidden' : ''
        window.document.body.style.position = value ? 'relative' : ''
        window.document.body.style.height = value ? '100%' : ''
      },
    },
  },

  beforeDestroy() {
    // When we close the modal we make sure that users can scroll on body again
    window.document.body.style.overflow = ''
    window.document.body.style.position = ''
    window.document.body.style.height = ''
  },

  mounted() {
    if (this.isOpen) {
      trackModal({ zone: this.zone, name: this.name, status: 'opened' })
      window.document.body.style.overflow = 'hidden'
      window.document.body.style.position = 'relative'
      window.document.body.style.height = '100%'
    }
  },

  methods: {
    async accept() {
      try {
        const acceptAll = Object.keys(COOKIES).reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {},
        )

        await this.$store.dispatch('gdpr/cookies/save', acceptAll)

        this.close()
      } catch (err) {
        this.displayToast()
      }
    },
    async refuse() {
      try {
        const refuseAll = Object.keys(COOKIES).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {},
        )

        await this.$store.dispatch('gdpr/cookies/save', refuseAll)

        this.close()
      } catch (err) {
        this.displayToast()
      }
    },
    backToFirstStep() {
      trackClick({ zone: this.zone, name: COOKIES_TRACKING_NAME.BACK })
      this.step = 0
    },
    goToSecondStep() {
      this.step = 1
    },
    displayToast() {
      this.isToastOpened = true
    },
    closeToast() {
      this.isToastOpened = false
    },
    close() {
      trackModal({ zone: this.zone, name: this.name, status: 'closed' })
      this.$store.dispatch('gdpr/cookies/closeModal')
    },
    focusTrap(event) {
      const { key, shiftKey, target } = event

      if (key === 'Tab') {
        const focusableList = this.$refs.dialog.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
        )

        if (focusableList.length < 2) {
          event.preventDefault()

          return
        }

        const last = focusableList.length - 1

        if (shiftKey === false && target === focusableList[last]) {
          event.preventDefault()
          focusableList[0].focus()
        } else if (shiftKey === true && target === focusableList[0]) {
          event.preventDefault()
          focusableList[last].focus()
        }
      }
    },
  },
}
