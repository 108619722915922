
import { RevContainer } from '@backmarket/design-system'

import CookiesSettings from '../CookiesSettings'

import translations from './CookiesModal.translations'

export default {
  components: {
    RevContainer,
    CookiesSettings,
  },
  props: {
    zone: {
      type: String,
      default: '',
    },
  },
  computed: {
    translations: () => translations,
  },
}
